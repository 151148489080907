<template>
  <v-layout column justify-center fill-height class="two-truths-reveal">
    <v-flex
      d-flex
      align-center
      v-if="isMissionCardHidden && isHost"
      class="visibility-link"
      @click.stop="onMissionCardToggle"
    >
      <v-icon class="visibility-icon"> visibility </v-icon>
      <span class="text">
        {{ currentMission.behavior }}
      </span>
    </v-flex>

    <v-layout
      shrink
      row
      justify-center
      class="buzz-main-row"
      :class="{
        compress: isHost,
        'multi-team-social': isMultiTeamLayout
      }"
    >
      <v-layout
        v-if="!isMissionCardHidden"
        column
        justify-center
        class="two-truths-reveal__cnt"
        :class="{ clickable: isHost }"
        @dblclick.stop="onMissionCardToggle"
      >
        <!-- Mission -->
        <transition name="flip-transition" mode="out-in">
          <v-layout column justify-center mission-container>
            <MissionContainer>
              <TwoTruthsAsset
                :key="`TwoTruths-${gameStatus.twoTruthsID}`"
                :mode="mode"
                v-if="missionType === 'Two Truths Reveal'"
              />
            </MissionContainer>
          </v-layout>
        </transition>
        <!--  -->
      </v-layout>
      <!-- player -->
      <div class="d-flex rtb-flex-wrap" v-if="!!player">
        <transition-group
          name="social-player-transition"
          tag="div"
          class="social-player-transition-wrap"
        >
          <SocialPlayerCard
            class="buzz-player-col"
            :key="`buzz-in-${player.id}-${player.teamID}`"
            :user="player"
            :allowKeys="nOfSelectedPlayers === 1"
            :readOnly="!showRating(player.id, player.teamID)"
            :score="getRatingScore(player.id, player.teamID)"
            :isWinner="isWinner(player.id)"
            @onStarred="num => onStarred(num, player)"
          />
        </transition-group>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import { db } from "@/firebase"

import * as moment from "moment"

const TwoTruthsAsset = () =>
  import("@/components/GroupTeams/Common/Games/TwoTruthsAsset.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")

const NUMBER_OF_STARS = 5

export default {
  name: "TwoTruthsReveal",
  components: {
    SocialPlayerCard: () =>
      import(
        "@/components/GroupTeams/Common/Player/SocialPlayerCard/SocialPlayerCard.vue"
      ),
    MissionContainer,
    TwoTruthsAsset
  },
  beforeDestroy() {
    this.votingRef?.off("value", this.onVoteUpdate)
  },
  data() {
    return {
      votes: {},
      firstScore: 0
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "ClientID"]),
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters(["onlineUsersArray", "missions", "gameID", "orgID"]),
    ...mapGetters({ mode: "getCurrentMode" }),
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters([
      "getCurrentMission",
      "missionPlaysArray",
      "onlineUsersArray",
      "missions",
      "gameStatus",
      "gameID"
    ]),
    player() {
      const player = this.users[this.gameStatus.twoTruthsID]
      return {
        ...player,
        score: this.getRatingScore(player.id, player.teamID),
        winner: this.isWinner(player.id)
      }
    },
    votingRef() {
      return this.orgID && this.gameID
        ? db.auxiliary().ref(`org/${this.orgID}/game/${this.gameID}/votes`)
        : undefined
    },
    isMissionCardHidden() {
      try {
        return !!this.missions[this.currentMissionID].hidden
      } catch (e) {
        console.warn(e)
        return false
      }
    },
    isMultiTeamLayout() {
      if (this.isMultiTeam) {
        if (this.isMissionCardHidden && this.nOfSelectedPlayers > 5) {
          return true
        } else if (!this.isMissionCardHidden && this.nOfSelectedPlayers > 3) {
          return true
        }
      }
      return false
    },
    selectedPlayersMiddleIndex() {
      return Math.ceil(this.nOfSelectedPlayers / 2)
    },
    nOfSelectedPlayers() {
      return this.selectedPlayers.length
    },
    selectedPlayers() {
      return this.onlineUsersArray
        .filter(({ selected }) => selected)
        .map(user => ({ ...user, icon: this.getTeamIconByTeamID(user.teamID) }))
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    missionPlays() {
      return this.$store.getters.missionPlays
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    missionType() {
      return this.currentMission.behavior
    },
    teams() {
      return this.$store.getters.chats
    },
    user() {
      return this.$store.getters.user
    },
    currentTeamID() {
      return this.$store.getters.teamID
    },
    numSelected() {
      return this.selectedPlayers.length
    },
    gameID() {
      return this.$store.getters.gameID
    },
    orgID() {
      return this.$store.getters.orgID
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    teamMission() {
      const playType = this.currentMission?.playType
      if (
        playType == "Team: Speed Matters" ||
        playType == "Team: Speed Does Not Matter" ||
        playType == undefined
      ) {
        return true
      }
      return false
    },
    playerCanRate() {
      return (
        this.currentMission.behavior === "Player Rating" && !this.user.selected
      )
    },
    speedMatters() {
      const playType = this.currentMission?.playType
      if (
        playType == "Team: Speed Matters" ||
        playType == "Individual: Speed Matters" ||
        playType == undefined
      ) {
        return true
      }
      return false
    }
  },
  watch: {
    votingRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onVoteUpdate)
        }
        if (newValue) {
          newValue.on("value", this.onVoteUpdate)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(["updateSocialMissionStatus"]),
    onVoteUpdate(snapshot) {
      this.votes = snapshot.val() || {}
    },
    getTeamIconByTeamID(teamID) {
      const teams = this.teams
      return teams && teams[teamID] ? teams[teamID].icon : null
    },
    async onMissionCardToggle() {
      if (!this.isHost) return
      await this.updateSocialMissionStatus({
        missionID: this.currentMissionID,
        hidden: !this.isMissionCardHidden
      })
    },
    showRating(userID, teamID) {
      return (this.playerCanRate && teamID != this.currentTeamID) || this.isHost
    },
    onStarred(rate, user) {
      console.log("THE USER", user)
      console.log("TEAMS =", this.teams)
      let foundVoteID
      if (this.votes) {
        let vArr = Object.entries(this.votes)
        for (let v in vArr) {
          if (
            vArr[v][1].ratedUserID == user.id &&
            vArr[v][1].votingUserID == this.user.id &&
            vArr[v][1].missionID == this.currentMissionID
          ) {
            foundVoteID = vArr[v][0]
          }
        }
      }

      const foundPlayObject = this.missionPlays
        .slice()
        .reverse()
        .find(({ userID }) => userID === user.id)
      const foundPlay =
        foundPlayObject && foundPlayObject.id ? foundPlayObject.id : null

      var obj = {}
      var sTime = new Date()
      if (foundVoteID) obj.id = foundVoteID
      obj.teamID = 0
      obj.ratedUserID = user.id
      obj.votingUserID = this.user.id
      obj.missionID = this.currentMissionID
      obj.mission = this.currentMission.name
      obj.points = this.currentMission.points
      obj.time = moment(sTime).unix()
      obj.show = false
      obj.vote = rate
      console.log("ADD VOTE", obj)
      this.$store.dispatch("addVote", obj)

      var score = parseInt(this.getRatingScore(user.id, user.teamID))
      var teamID = user.teamID
      obj = {}
      let teamScore
      if (foundPlay) {
        teamScore = this.teams[foundPlayObject.teamID].totalScore
        obj = foundPlayObject
        const oldScore = foundPlayObject.score
        obj.id = foundPlay
        obj.score = score
        teamScore = teamScore - oldScore
        teamScore = teamScore + score
      } else {
        teamScore = this.teams[teamID].totalScore
        var c = this.currentMission
        obj.behavior = c.behavior
        obj.correct = "vote"
        obj.mission = c.name
        obj.missionID = this.currentMissionID
        obj.points = c.points
        obj.score = score
        obj.result = true
        obj.show = true
        obj.teamID = teamID
        obj.time = moment(sTime).unix()
        obj.userID = user.id
      }
      console.log("ADD PLAY", obj)
      this.$store.dispatch("addPlay", obj)
      obj = {}
      obj.userID = user.id
      obj.teamID = teamID
      obj.clientID = this.clientID
      if (foundPlay) {
        obj.teamScore = teamScore
      } else {
        obj.teamScore = teamScore + score
      }
      obj.totalScore = score
      if (this.firstScore > 0) {
        score = score - this.firstScore
      }
      obj.score = score
      this.$store.dispatch("setTeamScore", obj)
      var ratedTeamID = user.teamID
      const teamMates = this.onlineUsersArray.filter(
        user => user.teamID === ratedTeamID
      )
      for (var i in teamMates) {
        obj = {}
        obj.score = score
        obj.userID = teamMates[i].id
        obj.clientID = this.clientID
        this.$store.dispatch("setScore", obj)
      }
      // this.$store.dispatch("setTeamScore", obj)
      if (this.firstScore == 0) {
        this.firstScore = score
      }
    },
    isWinner(userID) {
      return this.missionCompleted(userID)
    },
    missionCompleted(userID) {
      const arr = this.missionPlaysArray.filter(
        item =>
          // an alternative way to find a winner
          (parseInt(item.score) > 0 || item.result) &&
          item.userID === userID &&
          item.show
      )
      return arr.length > 0
    },
    getRatingScore(userID, teamID) {
      const avg = this.getAvgRating(userID)
      // get score based on the number of players for lipdub
      var points = 0
      if (this.currentMission.behavior == "Lipdub") {
        const players = this.numOfPlayers(teamID)
        points = parseInt(this.currentMission.points) / players
      } else {
        points = parseInt(this.currentMission.points)
      }
      const ratio = avg / NUMBER_OF_STARS
      return points * ratio
    },
    numOfPlayers(chat_id) {
      return this.onlineUsersArray.filter(({ teamID }) => teamID === chat_id)
        .length
    },
    getAvgRating(userID) {
      const currentMissionID = this.currentMissionID
      const votes = this.votes
      if (votes) {
        var vArr = Object.values(votes)
        vArr = vArr.filter(vote => {
          return (
            vote.ratedUserID == userID && vote.missionID == currentMissionID
          )
        })
        var totalVote = 0
        for (var v in vArr) {
          totalVote = totalVote + vArr[v].vote
        }
        if (vArr.length == 0) {
          return 0
        } else {
          return totalVote / vArr.length
        }
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
.two-truths-reveal {
  .flip-transition-enter-active,
  .flip-transition-leave-active {
    transition: all ease 0.3s;
    transform: rotateX(0deg);
  }
  .flip-transition-enter-active {
    transition-delay: 0.3s;
  }
  .flip-transition-enter {
    transform: rotateY(90deg);
  }
  .flip-transition-leave-to {
    transform: rotateY(-90deg);
  }

  // Social player animation
  // START
  .social-player {
    opacity: 1;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;
  }
  .social-player-transition-enter-active {
    transition: all 0.5s ease 0s;
  }
  .social-player-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .social-player {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }
  .social-player-transition-enter,
  .social-player-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .social-player {
      opacity: 0;
      transform: scale(0.3);
    }
  }
  // END

  .social-player-transition-wrap {
    display: flex;
    flex-shrink: 1;
    &.top {
      margin-bottom: 30px;
    }
  }
  .buzz-result {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
  }
  .buzz-result,
  .buzz-asset-mapper {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    background-color: $color-white;
    overflow: hidden;
    // padding: 4px 10px;
  }
  .two-truths-reveal__cnt {
    user-select: none;
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
    width: 33.33%;
    max-width: 600px;
    min-width: 220px;
    min-height: 34vmin;
    max-height: 34vmin !important;
    perspective: 2000px;
    transition: margin-right 0.5s ease 0.5s;
    &.clickable {
      cursor: pointer;
    }
  }
  .buzz-player-col {
    width: 34vmin;
    justify-content: center;
    max-width: 300px;
  }
  .compress {
    .two-truths-reveal__cnt {
      min-height: 30vmin;
      max-height: 30vmin;
    }
    .buzz-player-col {
      width: 25vmin;
    }
  }
  .mission-container {
    height: 100%;
  }
  .multi-team-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .multi-team-social .mission {
    max-height: 25vmin !important;
  }
  .multi-team-social {
    &.buzz-main-row {
      padding-bottom: 0;
    }
    .buzz-player-col {
      width: 25vmin;
      max-width: 25vmin;
    }
    .two-truths-reveal__cnt {
      min-height: 30vmin;
      max-height: 30vmin;
    }
    .mission-reveal {
      max-height: 30vmin !important;
    }
    .social-player {
      font-size: 0.9em;
      .buzz-player-name {
        font-size: 0.8em;
      }
    }
  }

  .visibility-link {
    background-color: $color-white;
    height: 30px;
    // width: 100px;
    padding: 0 7px;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 10;
    border: 2px solid $primary_accent_color;
    border-radius: 1em;
    user-select: none;
    cursor: pointer;
    i {
      color: $color-secondary-dark;
    }
    .text {
      padding-left: 3px;
      color: $primary_accent_color;
      text-decoration: underline;
    }
    &:hover {
      .text {
        filter: brightness(1.5);
      }
    }
  }
}
</style>
