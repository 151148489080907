var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "two-truths-reveal",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.isMissionCardHidden && _vm.isHost
        ? _c(
            "v-flex",
            {
              staticClass: "visibility-link",
              attrs: { "d-flex": "", "align-center": "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onMissionCardToggle.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { staticClass: "visibility-icon" }, [
                _vm._v(" visibility "),
              ]),
              _c("span", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.currentMission.behavior) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        {
          staticClass: "buzz-main-row",
          class: {
            compress: _vm.isHost,
            "multi-team-social": _vm.isMultiTeamLayout,
          },
          attrs: { shrink: "", row: "", "justify-center": "" },
        },
        [
          !_vm.isMissionCardHidden
            ? _c(
                "v-layout",
                {
                  staticClass: "two-truths-reveal__cnt",
                  class: { clickable: _vm.isHost },
                  attrs: { column: "", "justify-center": "" },
                  on: {
                    dblclick: function ($event) {
                      $event.stopPropagation()
                      return _vm.onMissionCardToggle.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "flip-transition", mode: "out-in" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            column: "",
                            "justify-center": "",
                            "mission-container": "",
                          },
                        },
                        [
                          _c(
                            "MissionContainer",
                            [
                              _vm.missionType === "Two Truths Reveal"
                                ? _c("TwoTruthsAsset", {
                                    key:
                                      "TwoTruths-" + _vm.gameStatus.twoTruthsID,
                                    attrs: { mode: _vm.mode },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !!_vm.player
            ? _c(
                "div",
                { staticClass: "d-flex rtb-flex-wrap" },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "social-player-transition-wrap",
                      attrs: { name: "social-player-transition", tag: "div" },
                    },
                    [
                      _c("SocialPlayerCard", {
                        key:
                          "buzz-in-" + _vm.player.id + "-" + _vm.player.teamID,
                        staticClass: "buzz-player-col",
                        attrs: {
                          user: _vm.player,
                          allowKeys: _vm.nOfSelectedPlayers === 1,
                          readOnly: !_vm.showRating(
                            _vm.player.id,
                            _vm.player.teamID
                          ),
                          score: _vm.getRatingScore(
                            _vm.player.id,
                            _vm.player.teamID
                          ),
                          isWinner: _vm.isWinner(_vm.player.id),
                        },
                        on: {
                          onStarred: function (num) {
                            return _vm.onStarred(num, _vm.player)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }